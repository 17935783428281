

    import { computed, defineComponent, ref, watch } from 'vue'
    import { useRoute, useRouter } from 'vue-router'
    import BlogCard from '../components/Blog/BlogCard.vue'
    import Container from '../components/Container.vue'
    import Grid from '../components/Grid/Grid.vue'
    import PageHeader from '../components/PageHeader.vue'
    import MetaInfo from '../components/MetaInfo.vue'
    import ThemeChanger from '../components/ThemeChanger.vue'
    import CategoryFilter from '../components/Blog/CategoryFilter.vue'
    import { Route } from '../enums/Route'
    import { useCms } from '../compositions/UseCms'
    import { useBlogType } from '../compositions/UseBlogType'
    import { useTheme } from '../compositions/UseTheme'
    import Paragraph from '../components/Paragraph.vue'
    import { shouldSavePagePosition } from '../compositions/App.ts'

    export default defineComponent({
        name: 'Blog',
        components: {
            Paragraph,
            Container,
            BlogCard,
            Grid,
            PageHeader,
            MetaInfo,
            ThemeChanger,
            CategoryFilter
        },
        setup() {

            const route = useRoute()
            const router = useRouter()
            const selectedCategory = ref(route.query.category || 'all') // 初始值设置为路由参数

            watch(() => route.query.category, (newCategory) => {

                selectedCategory.value = newCategory

            })

            watch(selectedCategory, (newCategory) => {

                router.replace({
                    name: route.name,
                    query: { ...route.query, category: newCategory },
                    params: { ...route.params, isSeamless: 'true' }
                })

            })

            useTheme().initializeCurrentThemeBasedOnRouteMeta()

            const { blog, blogs, isCNLocale } = useCms()
            const { isNormalBlogPost, isShareBlogPost } = useBlogType()

            const categories = computed(() => {

                const allCategories = blogs.value.reduce((result, item) => {

                    item.categories.forEach(category => (result[category.slug] = category.title))

                    return result

                }, {})

                return { all: 'ALL', ...allCategories }

            })

            const filteredBlogs = computed(() => {

                if (selectedCategory.value === 'all') {

                    return blogs.value

                }

                return blogs.value.filter(blog => blog.categories.some(category => category.slug === selectedCategory.value))

            })

            const selectedAll = computed(() => selectedCategory.value === 'all')

            router.beforeEach((to, from) => {

                shouldSavePagePosition.value = to.name === from.name

            })

            return {
                Route,
                cms: blog,
                blogs,
                isCNLocale,
                loadedItems: filteredBlogs,
                isNormalBlogPost,
                isShareBlogPost,
                categories,
                selectedCategory,
                selectedAll
            }

        }

    })

