

    import { defineComponent } from 'vue'

    export default defineComponent({
        name: 'CategoryFilter',
        props: {
            modelValue: String,
            categories: {
                type: Object,
                required: true
            }
        }
    })

