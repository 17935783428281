import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_CategoryFilter = _resolveComponent("CategoryFilter")!
  const _component_Grid = _resolveComponent("Grid")!
  const _component_Paragraph = _resolveComponent("Paragraph")!
  const _component_Container = _resolveComponent("Container")!
  const _component_BlogCard = _resolveComponent("BlogCard")!
  const _component_ThemeChanger = _resolveComponent("ThemeChanger")!
  const _component_MetaInfo = _resolveComponent("MetaInfo")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ThemeChanger, null, {
      default: _withCtx(() => [
        _createVNode(_component_PageHeader, {
          class: "pt-32 lg:pt-48",
          header: _ctx.cms.page_header_heading,
          "sub-header": _ctx.cms.page_header_content,
          "header-span": 5
        }, null, 8, ["header", "sub-header"]),
        _createVNode(_component_Container, {
          fluid: "",
          class: "mt-16"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Grid, null, {
              default: _withCtx(() => [
                _createVNode(_component_CategoryFilter, {
                  modelValue: _ctx.selectedCategory,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedCategory) = $event)),
                  categories: _ctx.categories
                }, null, 8, ["modelValue", "categories"])
              ]),
              _: 1
            }),
            _createVNode(_component_Grid, null, {
              default: _withCtx(() => [
                _createVNode(_component_Paragraph, { class: "pt-16" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.isCNLocale ? `已显示${_ctx.loadedItems.length}篇文章` : `Showing ${_ctx.loadedItems.length} articles`), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_Container, {
          fluid: "",
          class: "mt-16"
        }, {
          default: _withCtx(() => [
            (_ctx.selectedAll)
              ? (_openBlock(), _createBlock(_component_Grid, { key: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_BlogCard, {
                      blog: _ctx.loadedItems[0]
                    }, null, 8, ["blog"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_Grid, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedAll ? _ctx.loadedItems.slice(1, _ctx.loadedItems.length) : _ctx.loadedItems, (blog, index) => {
                  return (_openBlock(), _createBlock(_component_BlogCard, {
                    key: index,
                    blog: blog,
                    class: _normalizeClass([ !_ctx.selectedAll && index <= 1 ? '' : 'mt-25' ]),
                    "is-left": index % 2 == 0,
                    small: ""
                  }, null, 8, ["blog", "class", "is-left"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.cms.seo)
      ? (_openBlock(), _createBlock(_component_MetaInfo, {
          key: 0,
          seo: _ctx.cms.seo,
          "structured-data": _ctx.cms.seo_structured_data,
          published: _ctx.cms.date,
          modified: _ctx.cms.last_modified
        }, null, 8, ["seo", "structured-data", "published", "modified"]))
      : _createCommentVNode("", true)
  ], 64))
}