import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26b5b8ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "category-filter flex items-center" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (title, slug) => {
      return (_openBlock(), _createElementBlock("button", {
        key: slug,
        onClick: ($event: any) => (_ctx.$emit('update:modelValue', slug)),
        class: _normalizeClass(["category-filter__item", { '--selected': _ctx.modelValue === slug }])
      }, _toDisplayString(title), 11, _hoisted_2))
    }), 128))
  ]))
}